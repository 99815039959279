import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageNotFound from "../containers/PageNotFound";
import DefaultLayout from "@/containers/DefaultLayout/DefaultLayout";
import SignUp from "@/containers/SignUp";
import ForgotPassword from "../containers/ForgotPassword";
import AdminTaskReview from "../containers/Admin/Review";
import AdminRenewalTaskReview from "../containers/Admin/Review/Renewal";
import AdminPenaltyTaskReview from "../containers/Admin/Review/Penalty";
import ComplaintTaskReview from "../containers/Admin/Complaint";
import Dashboard from "../containers/Dashboard/Dashboard";
import UserProfile from "../containers/Profile";
import MemberBusinessProfile from "../containers/BusinessProfile";
import AdminProfile from "../containers/Admin/Profile";
import TeamProfile from "../containers/Admin/Team/Profile";
import Tasks from "../containers/Admin/Tasks";
import Transactions from "../containers/Admin/Transactions";
import NormalTransactions from "../containers/Transactions";
import TransactionOverview from "../containers/Admin/Transactions/transactionOverview";
import RegistrationTask from "../containers/Tasks/Registration";
import GeneralTask from "../containers/Admin/Tasks/taskOverview";
import PaymentTask from "../containers/Tasks/paymentTask";
import RenewalTask from "../containers/Tasks/renewalTask";
import PenaltyTask from "../containers/Tasks/penaltyTask";
import AuditTask from "../containers/Tasks/Audit/index";
import DirectTaskReview from "../containers/Tasks/directTask";
import Register from "../containers/Register";
import RegistrationSuccess from "../containers/Register/registrationSuccess";
import RegistrationResult from "../containers/Register/registrationResults";
import Verification from "../containers/Register/paymentVerifiction";
import Login from "../containers/Login";
import Members from "../containers/Admin/Members";
import Team from "../containers/Admin/Team";
import MemberOverview from "../containers/Admin/Members/MemberOverview";
import CompanyOverview from "../containers/Admin/Companies/CompanyOverview";
import Roles from "../components/UserManagement/ListRoles";
import Permissions from "../components/UserManagement/ListPermissions";
import Companies from "../containers/Admin/Companies";
import Reports from "../containers/Admin/Reports";
import BusinessUnits from "../containers/BusinessUnits/businessUnitList";
import Complaint from "../containers/Complaint";
import DebtCollectorComplaint from "../containers/Complaint/debtCollectorForm";
import UserTasks from "../containers/Tasks";
import Documents from "../containers/Documents";
import Employees from "../containers/Employees";
import RegisterManual from "../containers/Admin/Team/Register";
import BatchRegistration from "@/containers/Register/BatchRegistration/BatchRegistration";
import BatchRegistrationTask from "@/containers/Register/BatchRegistration/BatchRegistrationTask";
import BatchRegistrationComplete from "@/containers/Register/BatchRegistration/CompleteRegistration";
import BlackList from "@/components/UserManagement/BlackList";
import WithdrawnEmployment from "@/containers/Admin/WithdrawnEmployment/WithdrawnEmployment";
import PendingApplications from "@/containers/Admin/PendingApplications/PendingApplications";
import { protectedLoader } from "./loaders";
import InternalErrorPage from "@/containers/InternalErrorPage";
import BackgroundJobs from "@/containers/Admin/BackgroundJobs/BackgroundJobs";
import { NewMemberRegistration } from "@/containers/NewMemberRegistration/NewMemberRegistration";
import { ReportingDashboard } from "@/containers/Admin/Reporting/ReportingDashboard";
import { Reporting } from "@/containers/Admin/Reporting/Reporting";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    errorElement: <InternalErrorPage />,
    children: [
      {
        index: true,
        element: <div />,
        loader: protectedLoader,
      },
      {
        path: "profile",
        element: <UserProfile />,
        loader: protectedLoader,
      },
      {
        path: "business-profile",
        element: <MemberBusinessProfile />,
        loader: protectedLoader,
      },
      {
        path: "tasks",
        element: <UserTasks />,
        loader: protectedLoader,
      },
      {
        path: "documents",
        element: <Documents />,
        loader: protectedLoader,
      },
      {
        path: "admin/documents",
        element: <Documents />,
        loader: protectedLoader,
      },
      {
        path: "admin/dashboard",
        element: <Dashboard />,
        loader: protectedLoader,
      },
      {
        path: "employees",
        element: <Employees />,
        loader: protectedLoader,
      },
      {
        path: "register",
        element: <Register />,
        loader: protectedLoader,
      },
      {
        path: "register/employee-invitation/:invitationCode",
        element: <Register />,
        loader: protectedLoader,
      },
      {
        path: "register/results",
        element: <RegistrationSuccess />,
        loader: protectedLoader,
      },
      {
        path: "register/results/business",
        element: <RegistrationResult />,
        loader: protectedLoader,
      },
      {
        path: "payment-task-verification",
        element: <Verification />,
        loader: protectedLoader,
      },
      {
        path: "admin/team/manual-register",
        element: <RegisterManual />,
        loader: protectedLoader,
      },
      {
        path: "admin/team",
        element: <Team />,
        loader: protectedLoader,
      },
      {
        path: "admin/team/:teamMemberId",
        element: <TeamProfile />,
        loader: protectedLoader,
      },
      {
        path: "admin/members",
        element: <Members />,
        loader: protectedLoader,
      },
      {
        path: "admin/member/:memberId",
        element: <MemberOverview />,
        loader: protectedLoader,
      },
      {
        path: "admin/roles",
        element: <Roles />,
        loader: protectedLoader,
      },
      {
        path: "admin/permissions",
        element: <Permissions />,
        loader: protectedLoader,
      },
      {
        path: "admin/blacklist",
        element: <BlackList />,
        loader: protectedLoader,
      },
      {
        path: "admin/withdrawn-employment",
        element: <WithdrawnEmployment />,
        loader: protectedLoader,
      },
      {
        path: "admin/pending-applications",
        element: <PendingApplications />,
        loader: protectedLoader,
      },
      {
        path: "admin/companies",
        element: <Companies />,
        loader: protectedLoader,
      },
      {
        path: "admin/reports",
        element: <Reports />,
        loader: protectedLoader,
      },
      {
        path: "admin/reporting",
        element: <ReportingDashboard />,
        loader: protectedLoader,
      },
      {
        path: "admin/reporting/:reportType/:type",
        element: <Reporting />,
        loader: protectedLoader,
      },
      {
        path: "admin/company/:companyId",
        element: <CompanyOverview />,
        loader: protectedLoader,
      },
      {
        path: "admin/businessunits",
        element: <BusinessUnits />,
        loader: protectedLoader,
      },
      {
        path: "admin/profile",
        element: <AdminProfile />,
        loader: protectedLoader,
      },
      {
        path: "admin/tasks",
        element: <Tasks />,
        loader: protectedLoader,
      },
      {
        path: "admin/transactions",
        element: <Transactions />,
        loader: protectedLoader,
      },
      {
        path: "admin/transaction/:transactionId",
        element: <TransactionOverview />,
        loader: protectedLoader,
      },
      {
        path: "transactions",
        element: <NormalTransactions />,
        loader: protectedLoader,
      },
      {
        path: "admin/tasks/review",
        element: <AdminTaskReview />,
        loader: protectedLoader,
      },
      {
        path: "admin/tasks/review/renewal",
        element: <AdminRenewalTaskReview />,
        loader: protectedLoader,
      },
      {
        path: "admin/tasks/review/audit/:id",
        element: <AuditTask />,
        loader: protectedLoader,
      },
      {
        path: "admin/tasks/review/penalty",
        element: <AdminPenaltyTaskReview />,
        loader: protectedLoader,
      },
      {
        path: "admin/tasks/:id",
        element: <ComplaintTaskReview />,
        loader: protectedLoader,
      },
      {
        path: "admin/tasks/general/:id",
        element: <GeneralTask />,
        loader: protectedLoader,
      },
      {
        path: "tasks/payment/:id",
        element: <PaymentTask />,
        loader: protectedLoader,
      },
      {
        path: "tasks/renewal/:id",
        element: <RenewalTask />,
        loader: protectedLoader,
      },
      {
        path: "tasks/penalty/:id",
        element: <PenaltyTask />,
        loader: protectedLoader,
      },
      {
        path: "tasks/audit/:id",
        Component: AuditTask,
        loader: protectedLoader,
      },
      {
        path: "tasks/registration/:id",
        element: <RegistrationTask />,
        loader: protectedLoader,
      },
      {
        path: "tasks/direct/:id",
        element: <DirectTaskReview />,
        loader: protectedLoader,
      },
      {
        path: "register/batch-registration/:id",
        element: <BatchRegistration />,
        loader: protectedLoader,
      },
      {
        path: "register/batch-registration/:id/complete",
        element: <BatchRegistrationComplete />,
        loader: protectedLoader,
      },
      {
        path: "register/batch-registration/:id/create-task/:applicationId",
        element: <BatchRegistrationTask />,
        loader: protectedLoader,
      },
      {
        path: "/admin/background-jobs",
        element: <BackgroundJobs />,
        loader: protectedLoader,
      },
      {
        path: "/register/new",
        element: <NewMemberRegistration />,
        loader: protectedLoader,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <InternalErrorPage />,
  },
  {
    path: "/login/:emailVerificationToken",
    element: <Login />,
    errorElement: <InternalErrorPage />,
  },
  {
    path: "/signup",
    element: <SignUp />,
    errorElement: <InternalErrorPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <InternalErrorPage />,
  },
  {
    path: "/create-complaint",
    element: <Complaint />,
    errorElement: <InternalErrorPage />,
  },
  {
    path: "/create-complaint/:complaintId",
    element: <DebtCollectorComplaint />,
    errorElement: <InternalErrorPage />,
  },
  {
    path: "/employee-invitation/:invitationCode",
    element: <SignUp />,
    errorElement: <InternalErrorPage />,
  },
  {
    path: "/login/employee-invitation/:invitationCode",
    element: <Login />,
    errorElement: <InternalErrorPage />,
  },
  { path: "*", element: <PageNotFound /> },
]);

export default function Navigation() {
  return <RouterProvider router={router} />;
}
