import {
  FileSearchOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import { useNavigate } from "react-router";
import { ActiveMemberReportButton } from "../Reports/ActiveMemberReportButton";
import { useUserState } from "@/stores/user";
import { isSuperAdmin } from "@/utils";

const colors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
];

export function ReportingDashboard() {
  const navigate = useNavigate();
  const { activeUserRole, userRoles } = useUserState();

  const activeRole = userRoles.find((role) => role.id === activeUserRole);

  if (!isSuperAdmin(activeRole?.stream)) {
    return (
      <div className="d-flex m-5 h-100 justify-center">
        You do not have access to this page
      </div>
    );
  }

  return (
    <div className="d-flex m-2 h-100">
      <Card title="Reporting" className="m-0">
        <Typography.Title level={5}>Renewal Reports</Typography.Title>
        <Divider />

        <Row gutter={[24, 24]} className="mb-4">
          <Col span={6}>
            <Button
              color={colors[0]}
              size="large"
              className="w-100 h-3rem"
              icon={<FileSearchOutlined />}
              onClick={() => {
                navigate("/admin/reporting/renewals/subscription-control-list");
              }}
            >
              Subscription Control List
            </Button>
          </Col>

          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<HomeOutlined />}
              onClick={() => {
                navigate(
                  "/admin/reporting/renewals/future-subscription-control-list"
                );
              }}
            >
              Future Subscription Control List
            </Button>
          </Col>

          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<UserOutlined />}
              onClick={() => {
                navigate("/admin/reporting/renewals/renewal-fees-due");
              }}
            >
              Renewal Fees Due
            </Button>
          </Col>
        </Row>

        <Typography.Title level={5}>Custom Reports</Typography.Title>
        <Divider />

        <Row gutter={[24, 24]} className="mb-4">
          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<FileSearchOutlined />}
              onClick={() => {
                navigate("/admin/reporting/custom/audit");
              }}
            >
              Audit Report
            </Button>
          </Col>
          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<HomeOutlined />}
              onClick={() => {
                navigate("/admin/reporting/custom/company");
              }}
            >
              Company Report
            </Button>
          </Col>
          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<UserOutlined />}
              onClick={() => {
                navigate("/admin/reporting/custom/member");
              }}
            >
              Member Report
            </Button>
          </Col>
        </Row>

        <Typography.Title level={5}>Company Reports</Typography.Title>
        <Divider />

        <Row gutter={[24, 24]} className="mb-4">
          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<FileSearchOutlined />}
              onClick={() => {
                navigate("/admin/reporting/company/company-information");
              }}
            >
              Company Information
            </Button>
          </Col>

          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<HomeOutlined />}
              onClick={() => {
                navigate(
                  "/admin/reporting/company/business-statistics-summary"
                );
              }}
            >
              Business Statistics Summary
            </Button>
          </Col>

          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<UserOutlined />}
              onClick={() => {
                navigate("/admin/reporting/company/debt-collectors-per-area");
              }}
            >
              Debt Collectors Per Area
            </Button>
          </Col>

          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<UserOutlined />}
              onClick={() => {
                navigate("/admin/reporting/company/compliance-business");
              }}
            >
              Compliance (Business)
            </Button>
          </Col>
        </Row>

        <Typography.Title level={5}>Member Reports</Typography.Title>
        <Divider />

        <Row gutter={[24, 24]} className="mb-4">
          <Col span={6}>
            <ActiveMemberReportButton
              disabled={false}
              className="w-100 h-3rem"
            />
          </Col>
          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<FileSearchOutlined />}
              onClick={() => {
                navigate("/admin/reporting/member/employment-history");
              }}
            >
              Employment History
            </Button>
          </Col>
          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<HomeOutlined />}
              onClick={() => {
                navigate("/admin/reporting/member/contact-details-history");
              }}
            >
              Contact Details History
            </Button>
          </Col>
          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<UserOutlined />}
              onClick={() => {
                navigate("/admin/reporting/member/statistics-summary");
              }}
            >
              Statistics Summary
            </Button>
          </Col>

          <Col span={6}>
            <Button
              size="large"
              className="w-100 h-3rem"
              icon={<UserOutlined />}
              onClick={() => {
                navigate("/admin/reporting/member/compliance-member");
              }}
            >
              Compliance (Member)
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
