import { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Select, Button } from "antd";
import debounce from "lodash/debounce";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_COMPANIES, SEARCH_USERS } from "../graphql/queries";
import { notifyError } from "@/utils/notification";
import { useReportingFilters } from "./useReportingFilters";
import {
  ClearOutlined,
  DownloadOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { If } from "@/components/If";

export type FilterKey =
  | "dateRange"
  | "companyIds"
  | "employeeIds"
  | "type"
  | "months"
  | "sort"
  | "from"
  | "to"
  | "status"
  | "pageNumber"
  | "pageSize"
  | "reportType";

export type Filters = Record<FilterKey, string | number | string[]>;

type ReportingFilterProps = {
  loading?: boolean;
  filters: Filters & {
    clearFilters: () => void;
    setFilterState: (filters: Partial<Filters>) => void;
  };
};

const empty = [{ id: "empty", name: "No results found" }];

export function ReportingFilters({
  loading = false,
  filters,
}: ReportingFilterProps) {
  const [companySearchText, setCompanySearchText] = useState("");
  const [userSearchText, setUserSearchText] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [userList, setUserList] = useState([]);

  const {
    type,
    employeeIds,
    companyIds,
    //pageNumber,
    //pageSize,
    setFilterState,
    clearFilters,
  } = useReportingFilters();

  const handleCompanySearch = (txt: string) => {
    setCompanySearchText(txt);
  };

  const handleUserFilterUpdate = (txt: string) => {
    setUserSearchText(txt);
  };

  const debouncedSearchCompanies = useCallback(
    debounce(handleCompanySearch, 250),
    []
  );

  const debouncedSearchUser = useCallback(
    debounce(handleUserFilterUpdate, 250),
    []
  );

  const [getCompanies] = useLazyQuery(SEARCH_COMPANIES, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (
        data &&
        data.companiesByFilter &&
        data.companiesByFilter.companyList
      ) {
        if (data.companiesByFilter.companyList.length > 0) {
          setCompanyList(
            data.companiesByFilter.companyList.map(
              ({ id, registeredName }) => ({ name: registeredName, id })
            )
          );
        } else {
          setCompanyList(empty);
        }
      }
    },
    onError: (error) => {
      notifyError(error);
    },
  });

  const [getUsers] = useLazyQuery(SEARCH_USERS, {
    fetchPolicy: "cache-first",
    onCompleted: ({ searchUsers }) => {
      if (searchUsers) {
        if (searchUsers.length > 0) {
          setUserList(
            searchUsers.map(({ id, firstName, lastName }) => ({
              name: `${lastName} ${firstName}`,
              id,
            }))
          );
        } else {
          setCompanyList(empty);
        }
      }
    },
    onError: (error) => {
      notifyError(error);
    },
  });

  useEffect(() => {
    if (companySearchText?.length > 2) {
      setCompanyList([{ id: "loading", name: "Loading..." }]);
      getCompanies({
        variables: { filter: companySearchText, criteria: "searchName" },
      });
    } else {
      setCompanyList([
        { id: "3char", name: "Please enter at least 3 characters" },
      ]);
    }
  }, [companySearchText]);

  useEffect(() => {
    if (userSearchText?.length > 2) {
      setUserList([{ id: "loading", name: "Loading..." }]);
      getUsers({
        variables: { name: userSearchText },
      });
    } else {
      setUserList([
        { id: "3char", name: "Please enter at least 3 characters" },
      ]);
    }
  }, [userSearchText]);

  const showEmployeeFilter = useMemo(() => {
    return filters.reportType === "member";
  }, [filters.type]);

  const showCompanyFilter = useMemo(() => {
    return filters.reportType === "company";
  }, [filters.type]);

  const showGenerate = useMemo(() => {
    return type !== "employment-history";
  }, [type]);

  const showDownload = useMemo(() => {
    return type !== "employment-history";
  }, [type]);

  console.log(type);

  return (
    <div>
      <Row gutter={[24, 24]} className={loading ? "no-interact" : ""}>
        <If condition={showCompanyFilter}>
          <Col span={6}>
            <div className="flex-column gap-2">
              <label>Company:</label>
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                loading={false}
                style={{ width: "calc(100% - 75px)" }}
                onSearch={debouncedSearchCompanies}
                onSelect={(company) =>
                  setFilterState({ companyIds: [String(company)] })
                }
                placeholder="Search company"
                defaultValue={companyIds?.[0]}
              >
                {companyList.map((company) => (
                  <Select.Option key={company.id}>{company.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </If>

        <If condition={showEmployeeFilter}>
          <Col span={type === "employment-history" ? 12 : 6}>
            <div className="flex-column">
              <label>Employee:</label>
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                loading={false}
                onSearch={debouncedSearchUser}
                onSelect={(user) =>
                  setFilterState({ employeeIds: [String(user)] })
                }
                placeholder="Search user"
                value={employeeIds?.[0] ?? ""}
                className="w-100"
              >
                {userList.map((user) => (
                  <Select.Option key={user.id}>{user.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </If>
      </Row>

      <div className="flex-row gap-2 mt-3 justify-end">
        <If condition={showGenerate}>
          <Button
            disabled={loading}
            icon={<SendOutlined />}
            className="purple-button m-0"
          >
            Generate
          </Button>
        </If>
        <Button
          disabled={loading}
          icon={<ClearOutlined />}
          type="primary"
          danger
          onClick={clearFilters}
        >
          Clear
        </Button>
        <If condition={showDownload}>
          <Button disabled={loading} icon={<DownloadOutlined />} type="primary">
            Download
          </Button>
        </If>
      </div>
    </div>
  );
}
