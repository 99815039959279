import { Card, Divider, Empty } from "antd";
import { useReportingFilters } from "./useReportingFilters";
import { useMemo, useState } from "react";
import { ReportingFilters } from "./ReportingFilters";
import { EmploymentHistory } from "../EmploymentHistory/EmploymentHistory";
import { If } from "@/components/If";
import { useUserState } from "@/stores/user";
import { isSuperAdmin } from "@/utils";

export function Reporting() {
  const { activeUserRole, userRoles } = useUserState();
  const [loading, setLoading] = useState(false);
  const filters = useReportingFilters();
  console.log(filters, setLoading);
  const { reportType, type, employeeIds } = filters;

  const activeRole = userRoles.find((role) => role.id === activeUserRole);

  const reportName = useMemo(() => {
    return `${reportType.charAt(0).toUpperCase() + reportType.slice(1)} / ${type
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")}`;
  }, [reportType, type]);

  const showEmploymentHistory = useMemo(() => {
    return (
      reportType === "member" &&
      type === "employment-history" &&
      !!employeeIds?.[0]
    );
  }, [reportType, type, employeeIds]);

  const showEmpty = useMemo(() => {
    return !loading && !showEmploymentHistory;
  }, [loading, showEmploymentHistory]);

  if (!isSuperAdmin(activeRole?.stream)) {
    return (
      <div className="d-flex m-5 h-100 justify-center">
        You do not have access to this page
      </div>
    );
  }

  return (
    <div className="d-flex m-2 h-100">
      <Card title={`Reporting: ${reportName}`} className="m-0">
        <ReportingFilters filters={filters} loading={loading} />
        <Divider />

        <If condition={showEmpty}>
          <Empty
            className="mt-5"
            description="Use the filters above to generate a report."
          />
        </If>

        <If condition={showEmploymentHistory}>
          <EmploymentHistory userId={+employeeIds?.[0]} />
        </If>
      </Card>
    </div>
  );
}
