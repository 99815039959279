import { useCallback, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Filters } from "./ReportingFilters";

const PAGE_SIZE = 20;

export function useReportingFilters() {
  const location = useLocation();
  const navigate = useNavigate();
  const { reportType, type } = useParams();

  const [skip, setSkip] = useState<number>(0);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const dateRange = searchParams.get("dateRange")?.split(",");
  const companyIds = searchParams.get("companyIds")?.split(",");
  const employeeIds = searchParams.get("employeeIds")?.split(",");
  const months = searchParams.get("months")?.split(",");
  const sort = searchParams.get("sort") || "DESC";
  const fromYear = searchParams.get("from");
  const toYear = searchParams.get("to");
  const status = searchParams.get("status");

  const pageNumber = searchParams.get("pageNumber") || "1";
  const take = PAGE_SIZE;

  useEffect(() => {
    setSkip((+pageNumber - 1) * PAGE_SIZE);
  }, [pageNumber]);

  const clearFilters = useCallback(() => {
    searchParams.delete("dateRange");
    searchParams.delete("companyIds");
    searchParams.delete("employeeIds");
    searchParams.delete("months");
    searchParams.delete("sort");
    searchParams.delete("from");
    searchParams.delete("to");
    searchParams.delete("status");
    searchParams.delete("pageNumber");

    navigate(`?${searchParams.toString()}`, { replace: true });
  }, [history, searchParams]);

  const setFilterState = useCallback(
    (filters: Partial<Filters>) => {
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            searchParams.set(key, value.join(","));
          } else {
            searchParams.set(key, String(value));
          }
        } else {
          searchParams.delete(key);
        }
      });

      navigate(`?${searchParams.toString()}`, { replace: true });
    },
    [navigate, searchParams]
  );

  return {
    type,
    reportType,
    dateRange,
    companyIds,
    employeeIds,
    months,
    sort,
    from: fromYear,
    to: toYear,
    status,
    pageNumber: +pageNumber,
    pageSize: take,
    skip,
    setFilterState,
    clearFilters,
  };
}
